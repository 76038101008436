import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ReservationsPage from './ReservationsPage';
import TestimonialsPage from './TestimonialsPage';
import FAQPage from './FAQPage';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
          <h1><Link to="/">snappo</Link></h1>
          <ul>
              <li><Link to="/reservations">Reservations</Link></li>
              <li><Link to="/testimonials">Testimonials</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
          </nav>
        </header>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/reservations" element={<ReservationsPage />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/faq" element={<FAQPage />} />
        </Routes>

        <footer>
          <p>&copy; 2024 Snappo. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

function HomePage() {
  return (
    <section id="hero">
      <h2>Welcome to Our Product</h2>
      <p>Discover the best solution for your business needs.</p>
      <button>Get Started</button>
    </section>
  );
}

export default App;
