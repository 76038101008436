import React from 'react';

function ReservationsPage() {
  return (
    <section id="features">
      <h2>Reservations</h2>
      <div className="feature-list">
        <div className="feature">
          <h3>Book Now</h3>
          <p>Link to book...</p>
        </div>
        <div className="feature">
          <h3>Feature 2</h3>
          <p>Explanation of feature 2.</p>
        </div>
      </div>
    </section>
  );
}

export default ReservationsPage;
